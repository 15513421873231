import { Flex, Text, Button } from '@mantine/core';

import { useNavigate } from 'react-router-dom';

import { HiHome } from 'react-icons/hi'
import { FaGamepad } from 'react-icons/fa'
import { MdPrivacyTip } from 'react-icons/md'

const NavBar = ({ setOpened }) => {

    const navigate = useNavigate()

    return (
        <Flex direction='column' justify='center' align='center'>
            <Button onClick={() => { navigate("/"); setOpened(false); }} fullWidth size='md' variant='gradient' gradient={{ from: 'red', to: 'yellow', deg: 90 }} sx={{ marginBottom: 10 }}>
                <HiHome />
                <Text sx={{ marginLeft: 5 }}>
                    Home
                </Text>
            </Button>
            <Button onClick={() => { navigate("/games"); setOpened(false); }} fullWidth size='md' variant='gradient' gradient={{ from: 'red', to: 'yellow', deg: 90 }} sx={{ marginBottom: 10 }}>
                <FaGamepad />
                <Text sx={{ marginLeft: 5 }}>
                    Games
                </Text>
            </Button>
            <Button onClick={() => { navigate("/privacy"); setOpened(false); }} fullWidth size='md' variant='gradient' gradient={{ from: 'red', to: 'yellow', deg: 90 }} sx={{ marginBottom: 10 }}>
                <MdPrivacyTip />
                <Text sx={{ marginLeft: 5 }}>
                    Privacy
                </Text>
            </Button>
        </Flex>
    );

}

export default NavBar;
