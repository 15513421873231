import { useState } from 'react';
import { Flex, Text, Paper, Center } from '@mantine/core';

const Games = () => {

    const [opened, setOpened] = useState(false)

    return (
        <div>
            <Center>
                <Paper p='lg' shadow='lg' sx={{ maxWidth: 1000, width: '100%' }}>
                    <Flex justify='center' align='center'>
                        <Text>
                            Games coming soon!
                        </Text>
                    </Flex>
                </Paper>
            </Center>
        </div>
    );

}

export default Games;
