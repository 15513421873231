import { useState } from 'react';
import { AppShell, Flex, Header, Navbar, Image, Title, MediaQuery, Burger, Text, Footer } from '@mantine/core';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import NavBar from './components/NavBar/NavBar';
import Home from './components/Home/Home';
import Games from './components/Games/Games';
import Privacy from './components/Privacy/Privacy';

function App() {

  const [opened, setOpened] = useState(false)

  return (
    <BrowserRouter>
      <AppShell
        padding="md"
        navbar={
          <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, lg: 300 }} sx={{ marginTop: 3 }}>
            <NavBar setOpened={setOpened} />
          </Navbar>
        }
        header={
          <div>
            <Header height={{ base: 60, md: 60 }}>
              <div style={{ display: 'flex', alignItems: 'center', height: '100%', marginLeft: 10 }}>
                <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    mr="xl"
                  />
                </MediaQuery>
                <Flex justify='flex-start' align='center'>
                  <Image width={32} src='/images/flame_1.png' sx={{ marginRight: 12 }} />
                  <Title order={3}>
                    Hotkeys Games
                  </Title>
                </Flex>
              </div>
              <div style={{ width: '100%', height: 3, background: 'linear-gradient(to right, red, yellow)' }} />
            </Header>
          </div>
        }
        footer={
          <div>
            <Footer>
              <div style={{ height: 3, background: 'linear-gradient(to right, red, yellow)' }} />
              <Flex justify='center' align='center' p={12} direction='row' >
                <Text size={14}>
                  &copy; 2023 Hotkeys Games LLC
                </Text>
              </Flex>
            </Footer>
          </div>
        }
        styles={(theme) => ({
          main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
        })}>
        {
          <Routes>
            <Route index element={<Home />} />
            <Route path="/games" element={<Games />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        }
      </AppShell>
    </BrowserRouter>
  );

}

export default App;
