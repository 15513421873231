import { useState } from 'react';
import { Flex, Text, Paper, Center, ScrollArea } from '@mantine/core';

import PrivacyPolicyBody from './PrivacyPolicyBody';

const Privacy = () => {

    const [opened, setOpened] = useState(false)

    return (
        <div>
            <Center>
                <Paper p='lg' shadow='lg' sx={{ maxWidth: 1000, width: '100%', marginBottom: 100 }}>
                    <Flex justify='center' align='center'>
                        <PrivacyPolicyBody />
                    </Flex>
                </Paper>
            </Center>
        </div>
    );

}

export default Privacy;
